import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { ModalContent, ModalHeader } from 'modals';
import { List } from 'common/components/List/List';
import DisclaimerCheckbox from 'common/components/DisclaimerCheckbox/DisclaimerCheckbox';
import Button from '../../../common/components/Button/Button';
import ModalStyles from '../../../common/styles/Modal.styles';
import ModalLoadingIndicator from '../../../common/components/ModalLoadingIndicator/ModalLoadingIndicator';

const useModalStyles = createUseStyles(ModalStyles);

interface ReferralSettingUpdateConfirmationModalProps {
  closeModal: () => void;
  onAccept: any;
}

export default function ReferralSettingUpdateConfirmationModal({ closeModal, onAccept }: ReferralSettingUpdateConfirmationModalProps) {
  const modalClasses = useModalStyles();
  const [isAcceptedTerms, setIsAcceptedTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const confirm = async () => {
    setIsLoading(true);
    onAccept();
  };

  if (isLoading) {
    return <ModalLoadingIndicator />;
  }
  const disclaimerPoints = [
    'I consent to the release of my following personal information to my referrer: Name, Email, and Registration status.',
    "I release Bake (GS FINTECH, UAB) from all responsibility and liability in relation to my referrer's use of my personal information.",
  ];

  return (
    <>
      <ModalHeader title={'Reveal my personal information'} onClose={closeModal} cancelLabel="Close" />
      <ModalContent>
        <div className={modalClasses.contentMini}>
          <List items={disclaimerPoints} />
          <DisclaimerCheckbox isAcceptedTerms={isAcceptedTerms} setIsAcceptedTerms={setIsAcceptedTerms} />
        </div>
        <div className={modalClasses.footer}>
          <Button primary onClick={confirm} disabled={!isAcceptedTerms}><Trans>Reveal my information to my referrer</Trans></Button>
        </div>
      </ModalContent>
    </>
  );
}
